<template>
  <base-view>
    <view-section>
      <div v-if="!category">
        <p>Loading...</p>
      </div>
      <div v-else>
        <h1 class="mb-8 text-4xl font-semibold">{{ category.name }}</h1>

        <div class="flex flex-col flex-wrap justify-around md:flex-row">
          <div
            v-for="section in sections"
            :key="section.id"
            class="p-0 mb-4 overflow-hidden rounded-lg shadow-lg md:mb-8 md:mx-4 md:w-5/12"
          >
            <h2 class="p-4 m-0 text-white bg-primary">
              {{ section.name }}
            </h2>

            <div class="list">
              <router-link
                v-for="article in section.articles.slice(0, 5)"
                :key="article.id"
                :to="`/support/articles/${article.id}`"
                class="block p-4 border-0 border-b border-neutral border-solid hover:bg-neutral-light active:bg-neutral"
              >
                {{ article.name }}
              </router-link>
              <router-link
                v-if="section.articles.length > 5"
                :to="`/support/sections/${section.id}`"
                class="block p-4 text-sm inline-link"
              >
                See {{ section.articles.length - 5 }} More
                <span v-if="section.articles.length - 5 === 1">Article</span>
                <span v-else>Articles</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </view-section>
  </base-view>
</template>

<script>
import BaseView from "@/components/layout/BaseView.vue";
import ViewSection from "@/components/layout/ViewSection.vue";

import { mapGetters } from "vuex";

export default {
  components: {
    BaseView,
    ViewSection
  },
  name: "Category",
  data() {
    return {
      categoryId: parseInt(this.$route.params.categoryId)
    };
  },
  computed: {
    ...mapGetters(["allZendeskData"]),
    category() {
      return this.$store.getters.categoryById(this.categoryId);
    },
    sections() {
      return this.$store.getters.sectionsByCategoryId(this.categoryId);
    }
  },
  methods: {}
};
</script>
